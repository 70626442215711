import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsCool = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 41.25C9.875 37.25 9.375 31.875 14.375 30.625H30.625C36.25 31.875 36.0417 39.1667 36.25 41.25H9.375Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M14.5215 25.7221C14.5215 21.0131 14.5215 21.0954 14.5215 16.4029C14.5324 6.37648 30.2874 6.02015 30.2874 16.4193V26.5444"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.522 16.4029C14.522 21.0954 14.522 21.0132 14.522 25.7221C14.5262 27.0996 14.4493 28.4761 14.2917 29.8445H30.6113C30.3978 28.7611 30.2895 27.6596 30.2879 26.5554V16.4303C30.2879 6.02022 14.5329 6.37654 14.522 16.4029Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.522 16.4029C14.522 21.0954 14.522 21.0132 14.522 25.7221C14.5262 27.0996 14.4493 28.4761 14.2917 29.8445H30.6113C30.3978 28.7611 30.2895 27.6596 30.2879 26.5554V16.4303C30.2879 6.02022 14.5329 6.37654 14.522 16.4029Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2494 15.5754C29.471 6.32745 15.3935 6.62348 14.5603 15.5754H30.2494Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.2494 15.5754C29.471 6.32746 15.3935 6.62348 14.5603 15.5754H30.2494Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2185 21.8463C15.4011 21.8474 15.5799 21.7941 15.7322 21.6934C15.8845 21.5926 16.0034 21.4488 16.0737 21.2803C16.1441 21.1118 16.1628 20.9262 16.1275 20.747C16.0921 20.5679 16.0043 20.4033 15.8752 20.2741C15.746 20.145 15.5814 20.0572 15.4023 20.0218C15.2231 19.9865 15.0375 20.0052 14.869 20.0755C14.7005 20.1459 14.5567 20.2648 14.4559 20.4171C14.3551 20.5694 14.3019 20.7482 14.303 20.9308C14.303 21.1736 14.3994 21.4065 14.5711 21.5782C14.7428 21.7498 14.9757 21.8463 15.2185 21.8463Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M15.2185 21.8463C15.4011 21.8474 15.5799 21.7941 15.7322 21.6934C15.8845 21.5926 16.0034 21.4488 16.0737 21.2803C16.1441 21.1118 16.1628 20.9262 16.1275 20.747C16.0921 20.5679 16.0043 20.4033 15.8752 20.2741C15.746 20.145 15.5814 20.0572 15.4023 20.0218C15.2231 19.9865 15.0375 20.0052 14.869 20.0755C14.7005 20.1459 14.5567 20.2648 14.4559 20.4171C14.3551 20.5694 14.3019 20.7482 14.303 20.9308C14.303 21.1736 14.3994 21.4065 14.5711 21.5782C14.7428 21.7498 14.9757 21.8463 15.2185 21.8463Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9086 20.0318C22.7262 20.0307 22.5476 20.0838 22.3954 20.1843C22.2433 20.2848 22.1244 20.4283 22.0538 20.5965C21.9833 20.7646 21.9643 20.95 21.9992 21.129C22.0341 21.308 22.1214 21.4726 22.25 21.602C22.3785 21.7313 22.5426 21.8196 22.7214 21.8556C22.9002 21.8915 23.0857 21.8736 23.2543 21.8041C23.4229 21.7345 23.567 21.6165 23.6685 21.4649C23.7699 21.3134 23.8241 21.1351 23.8241 20.9527C23.8248 20.832 23.8016 20.7124 23.756 20.6007C23.7103 20.489 23.643 20.3874 23.5579 20.3018C23.4728 20.2162 23.3716 20.1483 23.2602 20.102C23.1488 20.0556 23.0293 20.0318 22.9086 20.0318V20.0318Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M22.9086 20.0318C22.7262 20.0307 22.5476 20.0838 22.3954 20.1843C22.2433 20.2848 22.1244 20.4283 22.0538 20.5965C21.9833 20.7646 21.9643 20.95 21.9992 21.129C22.0341 21.308 22.1214 21.4726 22.25 21.602C22.3785 21.7313 22.5426 21.8196 22.7214 21.8556C22.9002 21.8915 23.0857 21.8736 23.2543 21.8041C23.4229 21.7345 23.567 21.6165 23.6685 21.4649C23.7699 21.3134 23.8241 21.1351 23.8241 20.9527C23.8248 20.832 23.8016 20.7124 23.756 20.6007C23.7103 20.489 23.643 20.3874 23.5579 20.3018C23.4728 20.2162 23.3716 20.1483 23.2602 20.102C23.1488 20.0556 23.0293 20.0318 22.9086 20.0318V20.0318Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5636 17.3956C15.2025 17.3454 14.8345 17.4026 14.5056 17.5601"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.5206 17.3952C22.8818 17.344 23.25 17.4012 23.5786 17.5596"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1395 28.4253H14.5161C14.2217 28.4253 13.9394 28.5422 13.7312 28.7504C13.523 28.9586 13.406 29.241 13.406 29.5354C13.406 29.8298 13.523 30.1121 13.7312 30.3203C13.9394 30.5285 14.2217 30.6455 14.5161 30.6455H30.1395C30.4339 30.6455 30.7163 30.5285 30.9245 30.3203C31.1326 30.1121 31.2496 29.8298 31.2496 29.5354C31.2496 29.241 31.1326 28.9586 30.9245 28.7504C30.7163 28.5422 30.4339 28.4253 30.1395 28.4253V28.4253Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.1395 28.4253H14.5161C14.2217 28.4253 13.9394 28.5422 13.7312 28.7504C13.523 28.9586 13.406 29.241 13.406 29.5354C13.406 29.8298 13.523 30.1121 13.7312 30.3203C13.9394 30.5285 14.2217 30.6455 14.5161 30.6455H30.1395C30.4339 30.6455 30.7163 30.5285 30.9245 30.3203C31.1326 30.1121 31.2496 29.8298 31.2496 29.5354C31.2496 29.241 31.1326 28.9586 30.9245 28.7504C30.7163 28.5422 30.4339 28.4253 30.1395 28.4253V28.4253Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M30.2881 15.4932H6.25"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7634 15.4541L14.5277 16.4244C17.5683 15.9396 20.634 15.6284 23.7099 15.4925L14.7634 15.4541Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.7634 15.4541L14.5277 16.4244C17.5683 15.9396 20.634 15.6284 23.7099 15.4925L14.7634 15.4541Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8124 21.0407C14.7985 21.0399 14.7846 21.042 14.7716 21.047C14.7586 21.0519 14.7469 21.0596 14.737 21.0694C14.7272 21.0793 14.7195 21.0911 14.7146 21.1041C14.7096 21.1171 14.7075 21.131 14.7083 21.1448C14.7083 21.1725 14.7192 21.199 14.7388 21.2185C14.7583 21.238 14.7848 21.249 14.8124 21.249C14.8396 21.2477 14.8654 21.2363 14.8846 21.217C14.9039 21.1978 14.9153 21.172 14.9166 21.1448C14.9166 21.1172 14.9056 21.0907 14.8861 21.0712C14.8666 21.0517 14.8401 21.0407 14.8124 21.0407V21.0407Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.8124 21.0407C14.7985 21.0399 14.7846 21.042 14.7716 21.047C14.7586 21.0519 14.7469 21.0596 14.737 21.0694C14.7272 21.0793 14.7195 21.0911 14.7146 21.1041C14.7096 21.1171 14.7075 21.131 14.7083 21.1448C14.7083 21.1725 14.7192 21.199 14.7388 21.2185C14.7583 21.238 14.7848 21.249 14.8124 21.249C14.8396 21.2477 14.8654 21.2363 14.8846 21.217C14.9039 21.1978 14.9153 21.172 14.9166 21.1448C14.9166 21.1172 14.9056 21.0907 14.8861 21.0712C14.8666 21.0517 14.8401 21.0407 14.8124 21.0407V21.0407Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3942 21.2552C22.4081 21.256 22.422 21.2538 22.435 21.2489C22.448 21.2439 22.4598 21.2363 22.4696 21.2264C22.4794 21.2166 22.4871 21.2048 22.492 21.1918C22.497 21.1788 22.4991 21.1649 22.4983 21.151C22.4983 21.1234 22.4874 21.0969 22.4678 21.0774C22.4483 21.0578 22.4218 21.0469 22.3942 21.0469C22.367 21.0482 22.3413 21.0596 22.322 21.0789C22.3028 21.0981 22.2914 21.1238 22.29 21.151C22.29 21.1787 22.301 21.2051 22.3205 21.2247C22.3401 21.2442 22.3666 21.2552 22.3942 21.2552V21.2552Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M22.3942 21.2552C22.4081 21.256 22.422 21.2538 22.435 21.2489C22.448 21.2439 22.4598 21.2363 22.4696 21.2264C22.4794 21.2166 22.4871 21.2048 22.492 21.1918C22.497 21.1788 22.4991 21.1649 22.4983 21.151C22.4983 21.1234 22.4874 21.0969 22.4678 21.0774C22.4483 21.0578 22.4218 21.0469 22.3942 21.0469C22.367 21.0482 22.3413 21.0596 22.322 21.0789C22.3028 21.0981 22.2914 21.1238 22.29 21.151C22.29 21.1787 22.301 21.2051 22.3205 21.2247C22.3401 21.2442 22.3666 21.2552 22.3942 21.2552V21.2552Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6405 18.8701H30.2818"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5326 18.8701H25.4092V19.6814C25.4092 20.4607 25.0996 21.2081 24.5486 21.7591C23.9975 22.3102 23.2502 22.6197 22.4709 22.6197C21.6916 22.6197 20.9442 22.3102 20.3932 21.7591C19.8422 21.2081 19.5326 20.4607 19.5326 19.6814V18.8701Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M19.5326 18.8701H25.4092V19.6814C25.4092 20.4607 25.0996 21.2081 24.5486 21.7591C23.9975 22.3102 23.2502 22.6197 22.4709 22.6197C21.6916 22.6197 20.9442 22.3102 20.3932 21.7591C19.8422 21.2081 19.5326 20.4607 19.5326 19.6814V18.8701Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.576 18.8701H18.4525V19.6814C18.4525 20.4607 18.143 21.2081 17.5919 21.7591C17.0409 22.3102 16.2935 22.6197 15.5143 22.6197C14.735 22.6197 13.9876 22.3102 13.4366 21.7591C12.8855 21.2081 12.576 20.4607 12.576 19.6814V18.8701Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M12.576 18.8701H18.4525V19.6814C18.4525 20.4607 18.143 21.2081 17.5919 21.7591C17.0409 22.3102 16.2935 22.6197 15.5143 22.6197C14.735 22.6197 13.9876 22.3102 13.4366 21.7591C12.8855 21.2081 12.576 20.4607 12.576 19.6814V18.8701Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M23.8574 24.8606C24.0371 24.7674 24.1965 24.6394 24.3263 24.4841C24.4562 24.3289 24.5539 24.1494 24.6139 23.9561"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M24.0759 23.5957C24.2396 23.7313 24.4306 23.8299 24.6359 23.8848C24.8413 23.9397 25.056 23.9495 25.2655 23.9136"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312468"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>
);
