import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsDefault = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 40.625C5.5 36.625 6.875 31.25 10.625 30H26.25C31.875 31.25 31.6667 38.5417 31.875 40.625H5Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M26.7285 25.7243C26.7285 21.0155 26.7285 21.0922 26.7285 16.4054C26.7175 6.3794 10.9467 6.01761 10.9631 16.4273V26.5465"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7277 16.4052C26.7277 21.0921 26.7277 21.0153 26.7277 25.7241C26.7235 27.1033 26.8004 28.4816 26.958 29.8518H10.6555C10.8688 28.7684 10.9772 27.667 10.9789 26.5628V16.4436C10.946 6.01746 26.7168 6.37925 26.7277 16.4052Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M26.7277 16.4052C26.7277 21.0921 26.7277 21.0153 26.7277 25.7241C26.7235 27.1033 26.8004 28.4816 26.958 29.8518H10.6555C10.8688 28.7684 10.9772 27.667 10.9789 26.5628V16.4436C10.946 6.01746 26.7168 6.37925 26.7277 16.4052Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.001 15.5772C11.7794 6.32414 25.8563 6.62563 26.6896 15.5772H11.001Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M11.001 15.5772C11.7794 6.32414 25.8563 6.62563 26.6896 15.5772H11.001Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0327 21.8651C25.8516 21.8651 25.6746 21.8114 25.5241 21.7108C25.3735 21.6102 25.2562 21.4672 25.1869 21.2999C25.1176 21.1327 25.0995 20.9486 25.1348 20.771C25.1701 20.5934 25.2573 20.4303 25.3853 20.3023C25.5134 20.1743 25.6765 20.0871 25.8541 20.0518C26.0316 20.0164 26.2157 20.0346 26.383 20.1039C26.5503 20.1732 26.6932 20.2905 26.7938 20.441C26.8944 20.5916 26.9481 20.7686 26.9481 20.9496C26.9481 21.1924 26.8517 21.4253 26.68 21.5969C26.5083 21.7686 26.2755 21.8651 26.0327 21.8651V21.8651Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M26.0327 21.8651C25.8516 21.8651 25.6746 21.8114 25.5241 21.7108C25.3735 21.6102 25.2562 21.4672 25.1869 21.2999C25.1176 21.1327 25.0995 20.9486 25.1348 20.771C25.1701 20.5934 25.2573 20.4303 25.3853 20.3023C25.5134 20.1743 25.6765 20.0871 25.8541 20.0518C26.0316 20.0164 26.2157 20.0346 26.383 20.1039C26.5503 20.1732 26.6932 20.2905 26.7938 20.441C26.8944 20.5916 26.9481 20.7686 26.9481 20.9496C26.9481 21.1924 26.8517 21.4253 26.68 21.5969C26.5083 21.7686 26.2755 21.8651 26.0327 21.8651V21.8651Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.34 20.0342C18.5211 20.0342 18.698 20.0879 18.8486 20.1885C18.9991 20.289 19.1165 20.432 19.1858 20.5993C19.255 20.7666 19.2732 20.9506 19.2379 21.1282C19.2025 21.3058 19.1153 21.4689 18.9873 21.5969C18.8593 21.725 18.6962 21.8121 18.5186 21.8475C18.341 21.8828 18.157 21.8647 17.9897 21.7954C17.8224 21.7261 17.6794 21.6088 17.5788 21.4582C17.4783 21.3077 17.4246 21.1307 17.4246 20.9496C17.4246 20.7068 17.521 20.474 17.6927 20.3023C17.8644 20.1306 18.0972 20.0342 18.34 20.0342V20.0342Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M18.34 20.0342C18.5211 20.0342 18.698 20.0879 18.8486 20.1885C18.9991 20.289 19.1165 20.432 19.1858 20.5993C19.255 20.7666 19.2732 20.9506 19.2379 21.1282C19.2025 21.3058 19.1153 21.4689 18.9873 21.5969C18.8593 21.725 18.6962 21.8121 18.5186 21.8475C18.341 21.8828 18.157 21.8647 17.9897 21.7954C17.8224 21.7261 17.6794 21.6088 17.5788 21.4582C17.4783 21.3077 17.4246 21.1307 17.4246 20.9496C17.4246 20.7068 17.521 20.474 17.6927 20.3023C17.8644 20.1306 18.0972 20.0342 18.34 20.0342V20.0342Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6859 17.3976C26.047 17.3474 26.415 17.4046 26.7439 17.562"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M18.7301 17.3966C18.3689 17.3455 18.0007 17.4027 17.6721 17.5611"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.11 28.4038H26.7328C27.0272 28.4038 27.3096 28.5208 27.5178 28.7289C27.7259 28.9371 27.8429 29.2194 27.8429 29.5139C27.8429 29.8083 27.7259 30.0906 27.5178 30.2988C27.3096 30.5069 27.0272 30.6239 26.7328 30.6239H11.11C10.8156 30.6239 10.5333 30.5069 10.3251 30.2988C10.117 30.0906 10 29.8083 10 29.5139C10 29.2194 10.117 28.9371 10.3251 28.7289C10.5333 28.5208 10.8156 28.4038 11.11 28.4038V28.4038Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M11.11 28.4038H26.7328C27.0272 28.4038 27.3096 28.5208 27.5178 28.7289C27.7259 28.9371 27.8429 29.2194 27.8429 29.5139C27.8429 29.8083 27.7259 30.0906 27.5178 30.2988C27.3096 30.5069 27.0272 30.6239 26.7328 30.6239H11.11C10.8156 30.6239 10.5333 30.5069 10.3251 30.2988C10.117 30.0906 10 29.8083 10 29.5139C10 29.2194 10.117 28.9371 10.3251 28.7289C10.5333 28.5208 10.8156 28.4038 11.11 28.4038V28.4038Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M10.9631 15.4951H35.0003"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9521 30.5693H27.7635L28.75 31.2499C25.705 30.7576 21.0713 30.7364 17.9895 30.608L26.9521 30.5693Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4857 15.4565L26.7215 16.4268C23.6765 15.9345 20.605 15.6234 17.5232 15.4949L26.4857 15.4565Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M26.4857 15.4565L26.7215 16.4268C23.6765 15.9345 20.605 15.6234 17.5232 15.4949L26.4857 15.4565Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.938 20.7905C25.9662 20.7905 25.9933 20.8013 26.0137 20.8207C26.0341 20.8401 26.0463 20.8666 26.0477 20.8947C26.0477 20.9088 26.0448 20.9228 26.0393 20.9358C26.0337 20.9489 26.0255 20.9606 26.0153 20.9703C26.005 20.9801 25.9929 20.9876 25.9796 20.9925C25.9663 20.9974 25.9522 20.9996 25.938 20.9988C25.9104 20.9988 25.8839 20.9879 25.8644 20.9683C25.8449 20.9488 25.8339 20.9223 25.8339 20.8947C25.8352 20.8675 25.8466 20.8418 25.8659 20.8225C25.8851 20.8033 25.9108 20.7919 25.938 20.7905V20.7905Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M25.938 20.7905C25.9662 20.7905 25.9933 20.8013 26.0137 20.8207C26.0341 20.8401 26.0463 20.8666 26.0477 20.8947C26.0477 20.9088 26.0448 20.9228 26.0393 20.9358C26.0337 20.9489 26.0255 20.9606 26.0153 20.9703C26.005 20.9801 25.9929 20.9876 25.9796 20.9925C25.9663 20.9974 25.9522 20.9996 25.938 20.9988C25.9104 20.9988 25.8839 20.9879 25.8644 20.9683C25.8449 20.9488 25.8339 20.9223 25.8339 20.8947C25.8352 20.8675 25.8466 20.8418 25.8659 20.8225C25.8851 20.8033 25.9108 20.7919 25.938 20.7905V20.7905Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3573 21.0096C18.3297 21.0096 18.3032 20.9986 18.2837 20.9791C18.2641 20.9595 18.2532 20.933 18.2532 20.9054C18.2532 20.8778 18.2641 20.8513 18.2837 20.8318C18.3032 20.8122 18.3297 20.8013 18.3573 20.8013C18.3849 20.8013 18.4114 20.8122 18.431 20.8318C18.4505 20.8513 18.4615 20.8778 18.4615 20.9054C18.4615 20.933 18.4505 20.9595 18.431 20.9791C18.4114 20.9986 18.3849 21.0096 18.3573 21.0096V21.0096Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M18.3573 21.0096C18.3297 21.0096 18.3032 20.9986 18.2837 20.9791C18.2641 20.9595 18.2532 20.933 18.2532 20.9054C18.2532 20.8778 18.2641 20.8513 18.2837 20.8318C18.3032 20.8122 18.3297 20.8013 18.3573 20.8013C18.3849 20.8013 18.4114 20.8122 18.431 20.8318C18.4505 20.8513 18.4615 20.8778 18.4615 20.9054C18.4615 20.933 18.4505 20.9595 18.431 20.9791C18.4114 20.9986 18.3849 21.0096 18.3573 21.0096V21.0096Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7876 23.6299C20.0014 24.1452 20.5989 24.2109 21.0922 24.2109"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312456"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>
);
