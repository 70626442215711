import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsNerd = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 41.25C9.875 37.25 10.625 31.875 14.375 30.625H30.625C36.25 31.875 36.0417 39.1667 36.25 41.25H9.375Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M14.51 25.7459V16.4155C14.5429 6.37154 30.3278 6.01479 30.2949 16.4374V26.5692C30.2927 27.9521 30.458 29.2216 30.7364 30.4292H14.209C14.3997 29.0231 14.51 27.4802 14.51 25.7459Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.5105 25.7459C14.5105 21.0313 14.5105 21.1082 14.5105 16.4155C14.5434 6.37154 30.3283 6.01479 30.2954 16.4374V26.5692"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5103 16.416C14.5103 21.1087 14.5103 21.0319 14.5103 25.7465C14.5147 27.1256 14.4378 28.5038 14.2798 29.8738H30.619C30.3994 28.79 30.2909 27.6866 30.2952 26.5807V16.449C30.3281 6.01534 14.5432 6.37209 14.5103 16.416Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M14.5103 16.416C14.5103 21.1087 14.5103 21.0319 14.5103 25.7465C14.5147 27.1256 14.4378 28.5038 14.2798 29.8738H30.619C30.3994 28.79 30.2909 27.6866 30.2952 26.5807V16.449C30.3281 6.01534 14.5432 6.37209 14.5103 16.416Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2782 15.5816C29.4933 6.32249 15.4044 6.62436 14.5701 15.5816H30.2782Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.2782 15.5816C29.4933 6.32249 15.4044 6.62436 14.5701 15.5816H30.2782Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2296 21.8825C15.4099 21.8824 15.5862 21.8292 15.7365 21.7294C15.8867 21.6296 16.0041 21.4877 16.0741 21.3215C16.1441 21.1553 16.1635 20.9722 16.1298 20.795C16.0962 20.6178 16.0111 20.4545 15.8851 20.3255C15.7591 20.1965 15.5978 20.1075 15.4215 20.0697C15.2452 20.0319 15.0616 20.0469 14.8938 20.1129C14.726 20.179 14.5813 20.293 14.478 20.4408C14.3747 20.5887 14.3173 20.7637 14.313 20.9439C14.3101 21.0661 14.3316 21.1877 14.3764 21.3014C14.4211 21.4152 14.4882 21.5188 14.5736 21.6063C14.659 21.6938 14.761 21.7632 14.8737 21.8107C14.9863 21.8581 15.1073 21.8825 15.2296 21.8825V21.8825Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M15.2296 21.8825C15.4099 21.8824 15.5862 21.8292 15.7365 21.7294C15.8867 21.6296 16.0041 21.4877 16.0741 21.3215C16.1441 21.1553 16.1635 20.9722 16.1298 20.795C16.0962 20.6178 16.0111 20.4545 15.8851 20.3255C15.7591 20.1965 15.5978 20.1075 15.4215 20.0697C15.2452 20.0319 15.0616 20.0469 14.8938 20.1129C14.726 20.179 14.5813 20.293 14.478 20.4408C14.3747 20.5887 14.3173 20.7637 14.313 20.9439C14.3101 21.0661 14.3316 21.1877 14.3764 21.3014C14.4211 21.4152 14.4882 21.5188 14.5736 21.6063C14.659 21.6938 14.761 21.7632 14.8737 21.8107C14.9863 21.8581 15.1073 21.8825 15.2296 21.8825V21.8825Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9232 20.0493C22.7409 20.0494 22.5629 20.1037 22.4117 20.2054C22.2606 20.3072 22.1431 20.4516 22.0744 20.6204C22.0058 20.7892 21.9889 20.9746 22.0261 21.1529C22.0633 21.3313 22.1528 21.4946 22.2832 21.6218C22.4136 21.7491 22.579 21.8346 22.7582 21.8675C22.9374 21.9003 23.1223 21.879 23.2894 21.8062C23.4564 21.7335 23.598 21.6126 23.696 21.459C23.7941 21.3054 23.8441 21.1261 23.8397 20.9439C23.834 20.7047 23.7349 20.4771 23.5636 20.31C23.3924 20.1428 23.1625 20.0492 22.9232 20.0493Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M22.9232 20.0493C22.7409 20.0494 22.5629 20.1037 22.4117 20.2054C22.2606 20.3072 22.1431 20.4516 22.0744 20.6204C22.0058 20.7892 21.9889 20.9746 22.0261 21.1529C22.0633 21.3313 22.1528 21.4946 22.2832 21.6218C22.4136 21.7491 22.579 21.8346 22.7582 21.8675C22.9374 21.9003 23.1223 21.879 23.2894 21.8062C23.4564 21.7335 23.598 21.6126 23.696 21.459C23.7941 21.3054 23.8441 21.1261 23.8397 20.9439C23.834 20.7047 23.7349 20.4771 23.5636 20.31C23.3924 20.1428 23.1625 20.0492 22.9232 20.0493Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5745 17.4094C15.2128 17.3554 14.8434 17.4128 14.5152 17.574"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.5393 17.4093C22.9009 17.3564 23.2701 17.4138 23.5985 17.5739"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.164 28.4302H14.5108C14.2197 28.4302 13.9405 28.5458 13.7346 28.7517C13.5287 28.9575 13.4131 29.2367 13.4131 29.5279C13.4131 29.819 13.5287 30.0982 13.7346 30.3041C13.9405 30.5099 14.2197 30.6256 14.5108 30.6256H30.153C30.4441 30.6256 30.7233 30.5099 30.9292 30.3041C31.1351 30.0982 31.2507 29.819 31.2507 29.5279C31.2507 29.2367 31.1351 28.9575 30.9292 28.7517C30.7233 28.5458 30.4441 28.4302 30.153 28.4302H30.164Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M30.164 28.4302H14.5108C14.2197 28.4302 13.9405 28.5458 13.7346 28.7517C13.5287 28.9575 13.4131 29.2367 13.4131 29.5279C13.4131 29.819 13.5287 30.0982 13.7346 30.3041C13.9405 30.5099 14.2197 30.6256 14.5108 30.6256H30.153C30.4441 30.6256 30.7233 30.5099 30.9292 30.3041C31.1351 30.0982 31.2507 29.819 31.2507 29.5279C31.2507 29.2367 31.1351 28.9575 30.9292 28.7517C30.7233 28.5458 30.4441 28.4302 30.153 28.4302H30.164Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M30.3116 15.5049H6.25"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3064 30.5981L12.5 31.875C15.5543 31.3815 20.2 30.7627 23.2911 30.6311L14.3064 30.5981Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7739 15.4551L14.5105 16.4375C17.5612 15.9444 20.6382 15.631 23.7257 15.499L14.7739 15.4551Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.7739 15.4551L14.5105 16.4375C17.5612 15.9444 20.6382 15.631 23.7257 15.499L14.7739 15.4551Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8174 21.0537C14.7902 21.055 14.7644 21.0665 14.7452 21.0857C14.7259 21.105 14.7145 21.1308 14.7131 21.158C14.7131 21.1857 14.7241 21.2122 14.7437 21.2317C14.7632 21.2513 14.7898 21.2623 14.8174 21.2623C14.8313 21.2631 14.8452 21.2609 14.8582 21.256C14.8713 21.251 14.8831 21.2433 14.8929 21.2335C14.9028 21.2237 14.9104 21.2118 14.9154 21.1988C14.9203 21.1858 14.9225 21.1719 14.9217 21.158C14.9217 21.1303 14.9107 21.1038 14.8912 21.0843C14.8716 21.0647 14.8451 21.0537 14.8174 21.0537V21.0537Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M14.8174 21.0537C14.7902 21.055 14.7644 21.0665 14.7452 21.0857C14.7259 21.105 14.7145 21.1308 14.7131 21.158C14.7131 21.1857 14.7241 21.2122 14.7437 21.2317C14.7632 21.2513 14.7898 21.2623 14.8174 21.2623C14.8313 21.2631 14.8452 21.2609 14.8582 21.256C14.8713 21.251 14.8831 21.2433 14.8929 21.2335C14.9028 21.2237 14.9104 21.2118 14.9154 21.1988C14.9203 21.1858 14.9225 21.1719 14.9217 21.158C14.9217 21.1303 14.9107 21.1038 14.8912 21.0843C14.8716 21.0647 14.8451 21.0537 14.8174 21.0537V21.0537Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4136 21.2735C22.4413 21.2735 22.4678 21.2625 22.4874 21.243C22.5069 21.2234 22.5179 21.1969 22.5179 21.1692C22.5179 21.1416 22.5069 21.115 22.4874 21.0955C22.4678 21.0759 22.4413 21.0649 22.4136 21.0649C22.386 21.0649 22.3595 21.0759 22.3399 21.0955C22.3204 21.115 22.3094 21.1416 22.3094 21.1692C22.3094 21.1969 22.3204 21.2234 22.3399 21.243C22.3595 21.2625 22.386 21.2735 22.4136 21.2735Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M22.4136 21.2735C22.4413 21.2735 22.4678 21.2625 22.4874 21.243C22.5069 21.2234 22.5179 21.1969 22.5179 21.1692C22.5179 21.1416 22.5069 21.115 22.4874 21.0955C22.4678 21.0759 22.4413 21.0649 22.4136 21.0649C22.386 21.0649 22.3595 21.0759 22.3399 21.0955C22.3204 21.115 22.3094 21.1416 22.3094 21.1692C22.3094 21.1969 22.3204 21.2234 22.3399 21.243C22.3595 21.2625 22.386 21.2735 22.4136 21.2735Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2952 18.4576C15.7813 18.4533 16.2578 18.5934 16.6641 18.8604C17.0704 19.1273 17.3883 19.5089 17.5774 19.9568C17.7664 20.4047 17.8182 20.8987 17.726 21.376C17.6338 21.8533 17.4019 22.2925 17.0597 22.6378C16.7175 22.9832 16.2804 23.219 15.8039 23.3155C15.3274 23.4119 14.833 23.3646 14.3834 23.1796C13.9338 22.9946 13.5494 22.6802 13.2788 22.2762C13.0082 21.8723 12.8638 21.3971 12.8638 20.911C12.8637 20.2641 13.1192 19.6434 13.5746 19.1839C14.0299 18.7244 14.6483 18.4634 15.2952 18.4576V18.4576Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M22.8147 18.4575C23.2999 18.4575 23.7742 18.6014 24.1777 18.871C24.5812 19.1406 24.8956 19.5237 25.0813 19.972C25.267 20.4203 25.3156 20.9136 25.2209 21.3895C25.1262 21.8654 24.8926 22.3026 24.5495 22.6457C24.2064 22.9888 23.7692 23.2224 23.2933 23.3171C22.8174 23.4118 22.3241 23.3632 21.8758 23.1775C21.4275 22.9918 21.0444 22.6773 20.7748 22.2739C20.5052 21.8704 20.3613 21.3961 20.3613 20.9109C20.3613 20.2602 20.6198 19.6362 21.0799 19.1761C21.54 18.716 22.164 18.4575 22.8147 18.4575V18.4575Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M25.3931 20.5815H30.3053"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M17.4902 19.8465C18.0391 18.8476 19.971 18.7488 20.7504 19.7532"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M17.8029 24.7856H20.5801C20.5801 25.1539 20.4338 25.5071 20.1734 25.7675C19.913 26.0279 19.5598 26.1742 19.1915 26.1742C18.8233 26.1742 18.4701 26.0279 18.2096 25.7675C17.9492 25.5071 17.8029 25.1539 17.8029 24.7856Z"
      fill={colorTokens['chubbs-outline']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.312844"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>

);
