import { type FC } from 'react';
import { cn } from '@/lib/classNames';
import {
  resolveBadgeIcon,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/resolveBadgeIcon';
import { type UserAvatarBadgeType } from '@/controllers/graphql/generated';
import styles from './UserAvatarBadge.module.scss';

interface Props {
  type: UserAvatarBadgeType;
  className?: string;
}

export const UserAvatarBadge: FC<Props> = (props) => {
  const { type, className } = props;
  const BadgeIcon = resolveBadgeIcon(type);

  if (!BadgeIcon) {
    return null;
  }

  return (
    <div
      className={cn(styles.iconWrapper, className)}
      data-qa='user-avatar-badge'
    >
      <BadgeIcon className={styles.icon} />
    </div>
  );
};
