import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsSmile = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 40.625C8 36.625 8.75 31.25 12.5 30H28.75C34.375 31.25 34.1667 38.5417 34.375 40.625H7.5Z"
      fill={colorTokens['chubbs-body']}
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.3125"
    />
    <path
      d="M28.9061 25.587C28.9061 20.9166 28.9061 20.9927 28.9061 16.344C28.8898 6.39411 13.2527 6.05157 13.2691 16.3657V26.4026"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9071 16.3441C28.9071 20.9928 28.9071 20.9167 28.9071 25.5871C28.9029 26.9533 28.9792 28.3186 29.1355 29.6758H12.9601C13.1751 28.6017 13.2825 27.5089 13.2809 26.4136V16.3767C13.2537 6.05165 28.8908 6.39418 28.9071 16.3441Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M28.9071 16.3441C28.9071 20.9928 28.9071 20.9167 28.9071 25.5871C28.9029 26.9533 28.9792 28.3186 29.1355 29.6758H12.9601C13.1751 28.6017 13.2825 27.5089 13.2809 26.4136V16.3767C13.2537 6.05165 28.8908 6.39418 28.9071 16.3441Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3019 15.5176C14.0794 6.34523 28.0365 6.64427 28.8629 15.5176H13.3019Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M13.3019 15.5176C14.0794 6.34523 28.0365 6.64427 28.8629 15.5176H13.3019Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2102 21.7593C28.0307 21.7593 27.8551 21.7061 27.7058 21.6063C27.5565 21.5065 27.4401 21.3647 27.3714 21.1988C27.3026 21.0329 27.2847 20.8503 27.3197 20.6742C27.3547 20.4981 27.4412 20.3363 27.5682 20.2093C27.6952 20.0823 27.857 19.9958 28.0331 19.9608C28.2092 19.9258 28.3918 19.9438 28.5577 20.0125C28.7236 20.0812 28.8654 20.1976 28.9652 20.3469C29.065 20.4962 29.1182 20.6718 29.1182 20.8514C29.1182 21.0922 29.0226 21.3231 28.8523 21.4934C28.682 21.6637 28.4511 21.7593 28.2102 21.7593Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M28.2102 21.7593C28.0307 21.7593 27.8551 21.7061 27.7058 21.6063C27.5565 21.5065 27.4401 21.3647 27.3714 21.1988C27.3026 21.0329 27.2847 20.8503 27.3197 20.6742C27.3547 20.4981 27.4412 20.3363 27.5682 20.2093C27.6952 20.0823 27.857 19.9958 28.0331 19.9608C28.2092 19.9258 28.3918 19.9438 28.5577 20.0125C28.7236 20.0812 28.8654 20.1976 28.9652 20.3469C29.065 20.4962 29.1182 20.6718 29.1182 20.8514C29.1182 21.0922 29.0226 21.3231 28.8523 21.4934C28.682 21.6637 28.4511 21.7593 28.2102 21.7593Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5874 19.9434C20.7668 19.9444 20.9418 19.9986 21.0904 20.099C21.239 20.1995 21.3545 20.3416 21.4224 20.5076C21.4903 20.6736 21.5075 20.856 21.4719 21.0318C21.4362 21.2076 21.3493 21.3689 21.2221 21.4953C21.0949 21.6218 20.9331 21.7077 20.7572 21.7423C20.5812 21.7769 20.3989 21.7586 20.2333 21.6897C20.0677 21.6208 19.9262 21.5045 19.8267 21.3553C19.7271 21.2061 19.674 21.0307 19.674 20.8514C19.674 20.7317 19.6977 20.6132 19.7436 20.5026C19.7896 20.3921 19.857 20.2918 19.9419 20.2074C20.0268 20.123 20.1275 20.0562 20.2383 20.0109C20.3491 19.9656 20.4677 19.9427 20.5874 19.9434Z"
      fill={colorTokens['chubbs-eyes']}
    />
    <path
      d="M20.5874 19.9434C20.7668 19.9444 20.9418 19.9986 21.0904 20.099C21.239 20.1995 21.3545 20.3416 21.4224 20.5076C21.4903 20.6736 21.5075 20.856 21.4719 21.0318C21.4362 21.2076 21.3493 21.3689 21.2221 21.4953C21.0949 21.6218 20.9331 21.7077 20.7572 21.7423C20.5812 21.7769 20.3989 21.7586 20.2333 21.6897C20.0677 21.6208 19.9262 21.5045 19.8267 21.3553C19.7271 21.2061 19.674 21.0307 19.674 20.8514C19.674 20.7317 19.6977 20.6132 19.7436 20.5026C19.7896 20.3921 19.857 20.2918 19.9419 20.2074C20.0268 20.123 20.1275 20.0562 20.2383 20.0109C20.3491 19.9656 20.4677 19.9427 20.5874 19.9434Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7586 18.5565C28.073 18.3741 28.4357 18.2925 28.7979 18.3225"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M21.5835 18.4371C21.1783 18.1506 20.4961 17.9929 19.9524 18.2646"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4175 28.2402H28.9132C29.2016 28.2402 29.4782 28.3548 29.6821 28.5587C29.886 28.7627 30.0006 29.0393 30.0006 29.3277C30.0006 29.6161 29.886 29.8926 29.6821 30.0966C29.4782 30.3005 29.2016 30.4151 28.9132 30.4151H13.4175C13.129 30.4151 12.8525 30.3005 12.6485 30.0966C12.4446 29.8926 12.33 29.6161 12.33 29.3277C12.33 29.0393 12.4446 28.7627 12.6485 28.5587C12.8525 28.3548 13.129 28.2402 13.4175 28.2402Z"
      fill={colorTokens['chubbs-body']}
    />
    <path
      d="M13.4175 28.2402H28.9132C29.2016 28.2402 29.4782 28.3548 29.6821 28.5587C29.886 28.7627 30.0006 29.0393 30.0006 29.3277C30.0006 29.6161 29.886 29.8926 29.6821 30.0966C29.4782 30.3005 29.2016 30.4151 28.9132 30.4151H13.4175C13.129 30.4151 12.8525 30.3005 12.6485 30.0966C12.4446 29.8926 12.33 29.6161 12.33 29.3277C12.33 29.0393 12.4446 28.7627 12.6485 28.5587C12.8525 28.3548 13.129 28.2402 13.4175 28.2402Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M5 15.4307H28.8417"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8672 30.4254L29.912 30.3906L30.625 30.625L31.25 31.2497C28.2325 30.7691 23.3028 30.5605 20.2502 30.4254H28.8672Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M29.1236 30.3931L20.2502 30.4257"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5533 15.4688L28.7871 16.4311C25.7651 15.9427 22.7168 15.634 19.6582 15.5068L28.5533 15.4688Z"
      fill={colorTokens['chubbs-outline']}
    />
    <path
      d="M28.5533 15.4688L28.7871 16.4311C25.7651 15.9427 22.7168 15.634 19.6582 15.5068L28.5533 15.4688Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
    <path
      d="M28.5092 20.7316C28.5229 20.7308 28.5367 20.7329 28.5496 20.7379C28.5625 20.7428 28.5743 20.7504 28.584 20.7601C28.5938 20.7699 28.6014 20.7816 28.6063 20.7945C28.6112 20.8074 28.6133 20.8212 28.6125 20.835C28.6125 20.8624 28.6017 20.8887 28.5823 20.9081C28.5629 20.9275 28.5366 20.9384 28.5092 20.9384C28.4822 20.9371 28.4566 20.9258 28.4375 20.9066C28.4184 20.8875 28.4071 20.862 28.4058 20.835C28.4058 20.8076 28.4167 20.7813 28.436 20.7619C28.4554 20.7425 28.4817 20.7316 28.5092 20.7316V20.7316Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8983 20.9382C20.8845 20.939 20.8707 20.9369 20.8578 20.932C20.8449 20.9271 20.8332 20.9195 20.8234 20.9097C20.8137 20.8999 20.8061 20.8882 20.8012 20.8753C20.7962 20.8624 20.7941 20.8486 20.7949 20.8348C20.7949 20.8074 20.8058 20.7811 20.8252 20.7617C20.8446 20.7423 20.8709 20.7314 20.8983 20.7314C20.9126 20.7328 20.9265 20.737 20.9392 20.7438C20.9519 20.7505 20.9631 20.7597 20.9722 20.7709C20.9813 20.782 20.9882 20.7948 20.9923 20.8086C20.9964 20.8224 20.9977 20.8369 20.9962 20.8512C20.9925 20.8748 20.9807 20.8963 20.9628 20.9122C20.945 20.9281 20.9222 20.9373 20.8983 20.9382V20.9382Z"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7415 24.6133C23.8742 24.8404 24.0633 25.0294 24.2905 25.162C24.5177 25.2946 24.7753 25.3663 25.0383 25.3701C25.3014 25.3739 25.5609 25.3098 25.7919 25.1839C26.0229 25.0579 26.2174 24.8745 26.3567 24.6513"
      stroke={colorTokens['chubbs-outline']}
      strokeWidth="0.309914"
      strokeMiterlimit="22.93"
      strokeLinecap="round"
    />
  </svg>
);
